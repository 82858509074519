<template>
  <div>
    <div class="list-menu-title">
      <page-title :title="$t('supplier_products.title_emissions_data_linkage_registration')" />
    </div>
    <div class="listMenus-btn">
      <v-row no-gutters class="form-input-primary">
        <v-col cols="12">
          <div class="mb-3">
            <div class="title-btn title-btn-select-scope">
              <span>{{ $t('supplier_products.label_form_title') }}</span>
              <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
            </div>
            <div class="list-input-item">
              <input-field :label="$t('register_reduction.placeholder_input_title')" class="mt-2"
                v-model="formData.title" :inputValue="formData.title" @blur="handleCheckLengthTitle(formData.title)"
                @focus="errorMessages.title = []" :errorMessages="errorMessages.title" :isReadOnly="isView"
                id="title" />
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="my-3">
            <div class="title-btn title-btn-select-scope">
              <span>{{ $t('supplier_products.label_form_email') }}</span>
              <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
            </div>
            <div class="list-input-item">
              <input-field :label="$t('primary_data_merge.placeholder_email')" class="mt-2" v-model="formData.email"
                @blur="handleCheckEmail" @focus="errorMessages.email = []" :errorMessages="errorMessages.email"
                :inputValue="formData.email" :isReadOnly="isEdit" id="email" />
            </div>
          </div>
        </v-col>

        <LayersPulldown :formData="formData" :isEdit="isEdit" @updateBranchId="handleUpdateBranchId"
          :branchSelected="branchSelected" />

        <v-col cols="12" v-for="(item, index) in getListInputsCustom" :key="index">
          <div class="my-3">
            <div class="title-btn title-btn-select-scope">
              <span>{{ item.inputName }}</span>
              <div class="title-require" v-if="item.isRequired">{{ $t('list_menu.label_essential') }}</div>
            </div>
            <div class="list-input-item mt-2">
              <InfiniteScroll 
                v-if="item.typeInput === 'select'" 
                :dropdownElementId="item.keyInput+ 'dropdown'"
                :inputFieldElementId="item.keyInput+ 'inputField'"
                :tooltipColumn="item.keyInput + 'tooltipColumn'"
                :searchInput="item.keyInput + 'searchInput'"
                :items="item.items" 
                :model="formData[item.keyInput]" 
                :item-text="item.itemText"
                :item-value="item.itemValue"
                :isDisabled="item.isReadOnly"
                typeList="unit"
                :isCfp="true"
                :key="keyRender"
                :isReturnObject="true"
                @updateMasterDb="updateValuePulldown($event, item.keyInput)"
                :label="item.placeholder" />
              <input-field v-else class="mt-2" v-model="formData[item.keyInput]" :inputValue="formData[item.keyInput]"
                :id="`${item.typeInput}-${item.keyInput}`" :label="item.placeholder" :isReadOnly="item.isReadOnly" />
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="mt-3">
            <div class="title-btn title-btn-select-scope">
              <span>{{ $t('supplier_products.label_table_unit_create_form') }}</span>
               <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
            </div>
            <div class="listMenu-btn-text">
              <common-button
                class="listMenu-scope"
                @action="displayInputUnitScreen"
                :label="$t('supplier_products.button_action_input_unit')"
                :disabled="false"
                v-ripple="false"
                id="scope_select"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PageTitle from '@/components/pageTitle/pageTitle';
import InputField from '@/components/products/input/InputField.vue';
import validate from '@/utils/validate';
import LayersPulldown from '@/views/products/linkToPrimaryData/formInputPrimary/layersPulldown.vue';
import CommonButton from '@/components/utils/button.vue';
import { ROUTES } from '@/router/constants';
import InfiniteScroll from '@/components/pulldown/InfiniteScroll.vue';

import {
  getNameCodeProductApprovedList,
  getNoListByProductName,
  getBoundaryProcessByLotId,
} from '@/api/product/emissionsProduct';
import { formatDateTimeDataTable } from "@/utils/datetimeFormat";

export default {
  components: {
    PageTitle,
    InputField,
    LayersPulldown,
    CommonButton,
    InfiniteScroll
  },
  data() {
    return {
      breadcrumb: [
        {
          text: this.$t('supplier_products.link_data_linkage'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_PRIMARY_DATA_MERGE,
        },
        {
          text: this.$t('supplier_products.link_emissions_data_linkage'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_FORM_PRIMARY_DATA_MERGE,
        },
        {
          text: this.$t('supplier_products.link_emissions_data_linkage_registration'),
          disabled: true,
          href: ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_CREATE_FORM_LINK_PRIMARY_DATA,
        },
      ],
      keyRender: 0,
      formData: {
        title: null,
        email: null,
        lotNo: null,
        branch_id: null,
        productCode: '',
        productPeriod: '',
        productName: null,
        processes: null,
        boundaryName: null,
      },
      dataList: {
        productItems: [],
        lotItems: [],
        boundaryItems: [],
        processesItems: [],
      },
      errorMessages: {
        title: [],
        email: [],
      },
      isFixedButton: true,
      filterKeys: ['productName', 'lotNo', 'productPeriod', 'boundaryName', 'processes'],
      filterItems: ['productItems', 'lotItems', 'boundaryItems', 'processesItems'],
      branchSelected: {},
      isView: false,
      titleEdit: '',
    };
  },
  async mounted() {
    await Promise.all([this.updateBreadCrumb(this.breadcrumb), this.handleGetProductNameCodeList()]);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {
    typeForm: {
      type: String,
      default: 'create',
    },
    dataFormDetail: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    isEdit() {
      return this.typeForm === 'edit';
    },
    getListInputsCustom() {
      const listInputsCustom = [
        {
          isReadOnly: this.isEdit,
          isRequired: true,
          typeInput: 'select',
          keyInput: 'productName',
          itemText: 'name',
          itemValue: 'name',
          items: this.dataList.productItems,
          inputName: this.$t('supplier_products.label_form_product_name'),
          placeholder: this.$t('supplier_products.placeholder_form_product_name'),
        },
        {
          isReadOnly: true,
          isRequired: false,
          typeInput: 'input',
          keyInput: 'productCode',
          inputName: this.$t('supplier_products.label_form_product_code'),
          placeholder: this.$t('supplier_products.placeholder_form_product_code'),
        },
        {
          isReadOnly: this.isEdit || !this.formData.productName,
          isRequired: true,
          keyInput: 'lotNo',
          typeInput: 'select',
          items: this.dataList.lotItems,
          itemText: 'lot_number',
          itemValue: 'id',
          inputName: this.$t('supplier_products.label_form_lot_no'),
          placeholder: this.$t('supplier_products.placeholder_form_lot_no'),
        },
        {
          isReadOnly: true,
          isRequired: false,
          typeInput: 'input',
          keyInput: 'productPeriod',
          inputName: this.$t('supplier_products.label_form_product_period'),
          placeholder: this.$t('supplier_products.placeholder_form_product_period'),
        },
        {
          isReadOnly: this.isEdit || !this.formData.lotNo,
          isRequired: true,
          typeInput: 'select',
          keyInput: 'boundaryName',
          itemText: 'name',
          itemValue: 'id',
          items: this.dataList.boundaryItems,
          inputName: this.$t('supplier_products.label_form_boundary_name'),
          placeholder: this.$t('supplier_products.placeholder_form_boundary_name'),
        },
        {
          isReadOnly: this.isEdit || !this.formData.boundaryName,
          isRequired: true,
          typeInput: 'select',
          keyInput: 'processes',
          itemText: 'name',
          itemValue: 'name',
          items: this.dataList.processesItems,
          inputName: this.$t('supplier_products.label_form_project_name'),
          placeholder: this.$t('supplier_products.placeholder_form_project_name'),
        },
      ];
      return listInputsCustom;
    },
  },
  watch: {
    'formData.productName': function (newValue) {
      if (!this.isEdit) {
        if (newValue && newValue?.name) {
          this.clearDataAfterField('productName', this.filterKeys, this.formData);
          this.clearDataAfterField('productItems', this.filterItems, this.dataList);
          const selectedProduct = this.dataList.productItems.find((item) => item.name === newValue.name);
          if (selectedProduct) {
            this.formData.productCode = selectedProduct.code;
          }
          this.handleGetListNoByProductName(newValue);
          this.branchSelected = {};
          this.keyRender += 1;
        } else {
          this.formData.productCode = '';
        }
      }
    },

    'formData.lotNo': function (newValue) {
      if (!this.isEdit) {
        if (newValue && newValue?.id) {
          this.clearDataAfterField('lotNo', this.filterKeys, this.formData);
          this.clearDataAfterField('lotItems', this.filterItems, this.dataList);
          const selectedLot = this.dataList.lotItems.find((item) => item.id === newValue.id);
          if (selectedLot) {
            const { start_date, end_date } = selectedLot;
            const startDateFormat = formatDateTimeDataTable(start_date, true);
            const endDateFormat = formatDateTimeDataTable(end_date, true);
            this.formData.productPeriod =
              start_date && end_date ? `${startDateFormat} - ${endDateFormat}` : startDateFormat || endDateFormat || null;
          }
          this.handleGetBoundaryProcess(newValue);
          this.branchSelected = selectedLot.branch;
          this.keyRender += 1;
        } else {
          this.formData.productPeriod = '';
        }
      }
    },

    'formData.boundaryName': function (newValue) {
      if (!this.isEdit) {
        if (newValue) {
          this.clearDataAfterField('boundaryName', this.filterKeys, this.formData);
          this.clearDataAfterField('boundaryItems', this.filterItems, this.dataList);
          const selectedBoundary = this.dataList.boundaryItems.find((item) => item.id === newValue.id);
          if (selectedBoundary) {
            this.dataList.processesItems = selectedBoundary?.processes;
          }
          this.keyRender += 1;
        } else {
          this.formData.processesItems = [];
        }
      }
    },
    formData: {
      handler: function (newVal) {
        this.$emit('getDataForm', newVal);
      },
      deep: true,
    },
    dataFormDetail: {
      handler: function (newVal) { 
        this.updateFormDetail(newVal)
      }
    }
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    clearDataAfterField(startKey, fieldsList, dataObject) {
      const startIndex = fieldsList.findIndex((key) => key === startKey);
      if (startIndex !== -1) {
        for (let i = startIndex + 1; i < fieldsList.length; i++) {
          dataObject[fieldsList[i]] = null;
        }
      }
    },
    displayInputUnitScreen() {
      this.$emit('getDataForm', true);
    },
    updateValuePulldown(value, type) {
      this.$set(this.formData, type, value);
    },
    async handleGetProductNameCodeList() {
      const nameCodeList = await getNameCodeProductApprovedList();
      this.dataList.productItems = nameCodeList.data;
    },
    async handleGetListNoByProductName(value) {
      const name = { name: value.name };
      const lotNoList = await getNoListByProductName(name);
      this.dataList.lotItems = lotNoList.data;
    },
    async handleGetBoundaryProcess(value) {
      const id = value.id;
      const boundaryList = await getBoundaryProcessByLotId(id);
      this.dataList.boundaryItems = boundaryList.data;
    },
    updateFormDetail(dataForm) {
      this.isView = !dataForm.status;
      this.formData = {
        title: dataForm.title,
        email: dataForm.user.email,
        organizational: dataForm.branch?.organizational_division,
        company: dataForm.branch?.company_name,
        productName: dataForm.cfp_product,
        lotNo: dataForm.cfp_product,
        boundaryName: dataForm.cfp_boundary,
        processes: dataForm.cfp_process,
        titleEdit: dataForm.title
      };
      this.dataList = {
        productItems: [dataForm.cfp_product],
        lotItems: [dataForm.cfp_product],
        boundaryItems: [dataForm.cfp_boundary],
        processesItems: [dataForm.cfp_process],
      };

      const { start_date, end_date } = dataForm.cfp_product;
      const startDateFormat = formatDateTimeDataTable(start_date, true);
      const endDateFormat = formatDateTimeDataTable(end_date, true);
      this.formData.productPeriod =
        start_date && end_date ? `${startDateFormat} - ${endDateFormat}` : startDateFormat || endDateFormat || null;
      this.formData.productCode = dataForm.cfp_product.code;
      this.branchSelected = dataForm.cfp_product?.branch;
    },
    handleUpdateBranchId(branch_id) {
      this.formData.branch_id = branch_id;
    },

    handleCheckLengthTitle(value) {
      if (value.length > 255) {
        this.errorMessages.title.push(this.$t('validation.error_maximum_20_charactors', { charactors: '255' }));
      }
    },
    handleCheckEmail(email) {
      if (email) {
        if (!validate.regexMail.test(email)) {
          this.errorMessages.email.push(this.$t('primary_data_merge.error_format_email'));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/products/linkToPrimaryData/styles/index.scss';
</style>
