<template>
  <div class="create-primary list-layers">
    <v-col cols="12">
      <div class="title-btn title-btn-select-scope mt-3">
        <span>{{ $t('reduction.label_organization_categories') }} </span>
        <!-- <div class="title-require">{{ $t('list_menu.label_essential') }}</div> -->
      </div>
    </v-col>

    <div class="d-md-flex justify-md-space-between input-select-group">
      <div class="list-input-item organization mb-3 mt-2" v-if="!isLoadingData">
        <v-select
          solo
          flat
          :items="dataList.organizational"
          v-model="formData.organizational"
          item-text="text"
          item-value="id"
          :placeholder="$t(branchIsNull ? 'supplier_products.placeholder_branch_null_organization' : 'primary_data_merge.placeholder_organization')"
          class="select-type select-default list-menu--select select-item"
          :menu-props="{ contentClass: 'select-menu export-select' }"
          :disabled="true"
          @change="updateData('organizational')"
          id="select-organizational"
          :key="keyRender"
        />
      </div>

      <div class="list-input-item company mb-3 mt-2" v-if="!isLoadingData">
        <v-select
          solo
          flat
          return-object
          :items="dataList.companies"
          v-model="formData.company"
          item-text="text"
          item-value="text"
          :placeholder="$t(branchIsNull ? 'supplier_products.placeholder_branch_null_company_name' : 'primary_data_merge.placeholder_company')"
          class="select-type select-default list-menu--select select-item"
          :menu-props="{ contentClass: 'select-menu export-select' }"
          :disabled="true"
          @change="updateData('company')"
          id="select-company"
          :key="keyRender"
        />
      </div>
    </div>

    <v-col cols="12" v-if="isHiddenLayer">
      <div class="my-3">
        <div class="title-btn title-btn-select-scope">
          <span>{{ $t('primary_data_merge.input_field_layer') }} </span>
          <!-- <div class="title-require">{{ $t('list_menu.label_essential') }}</div> -->
        </div>
        <template v-for="(data, index) in listLayersVisible">
          <div class="list-input-item mt-2" :key="index" v-if="data.isShowLayer">
            <v-select
              solo
              flat
              return-object
              :items="data.items"
              v-model="data.valueSelected"
              :item-text="data.key"
              :item-value="data.key"
              :placeholder="
                branchIsNull
                  ? data.name
                  : isEdit && !data.valueSelected
                  ? $t('register_data.checkbox_blank')
                  : $t('primary_data_merge.placeholder_layer', { layerName: data.name })
              "
              class="select-type select-db select-default list-menu--select select-item"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              :disabled="true"
              @change="updateData(data.key)"
              :id="`select-${data.key}`"
            />
          </div>
        </template>
      </div>
    </v-col>
  </div>
</template>

<script>
import { getListDetailBranch } from '@/api/registerData';
import { getListBranchLayerVisible } from '@/api/branch';
import { prepareBranchData } from '@/utils/registerData';
import { TEXT_NULL } from '@/constants/dashboard';
import { getBranchId } from '@/concerns/utils/filter-data';
export default {
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    branchSelected: {
      default: null,
    }
  },
  data() {
    return {
      listLayersVisible: [],
      filterIndex: [],
      isLoadingData: true,
      dataList: {
        organizational: [],
        companies: [],
        companyBranchIds: [],
      },
      filterTypes: [
        'organizational',
        'company',
        'business_name',
        'country',
        'layer_3',
        'layer_4',
        'layer_5',
        'layer_6',
      ],
      layersVisible: [],
      branchIsNull: false,
      isCompleteMounted: false,
      keyRender: 0
    };
  },
  async mounted() {
    await Promise.all([this.handleGetBranchData(), this.handleGetListBranchLayerVisible()]);
    this.defaultData();
    this.isCompleteMounted = true;
  },
  watch: {
    watchData: {
      handler() {
        if (!this.isCompleteMounted || (this.branchSelected && Object.keys(this.branchSelected).length === 0)) return;
        this.branchIsNull = false;
        if (this.branchSelected) {
          this.autoFillBrach(this.branchSelected);
        }
        else {
          this.defaultLayerData();
          this.formData.organizational = null;
          this.formData.company = null;
          this.branchIsNull = true;
          this.keyRender ++;
        }
      },
    }
  },
  computed: {
    watchData() {
      return JSON.stringify(this.branchSelected) + this.isCompleteMounted.toString();
    },
    isHiddenLayer() {
      const listLayerShow = this.listLayersVisible.filter((item) => !item.isShowLayer);
      return listLayerShow.length !== this.listLayersVisible.length;
    },
  },
  methods: {
    defaultData() {
      this.isLoadingData = false;
      this.defaultLayerData();

      this.branchData.filterPatternList.forEach((item) => {
        this.addOrganizational(item);
        this.addCompany(item);
      });
      this.removeDuplicateData(this.dataList.organizational, 'organizational');
      this.removeDuplicateData(this.dataList.companies, 'companies');
    },
    async handleGetBranchData() {
      this.isLoadingData = true;
      const response = await getListDetailBranch();
      this.listLayerResponse = response.layer;
      this.branchData = prepareBranchData(response);
    },
    
    async handleGetListBranchLayerVisible() {
      this.isLoadingData = true;
      const response = await getListBranchLayerVisible();
      this.layersVisible = response.data;
    },
    removeDuplicateData(arr, type) {
      this.dataList[type] = [...new Map(arr.map((item) => [item['text'], item])).values()];
    },
    autoFillBrach(branch) {
      this.listLayersVisible.map((item) => {
        let data = item;
        data.valueSelected = branch[data.key];
        return data;
      });
      this.formData.organizational = branch.organizational_division
      this.formData.company = branch.company_name
      this.keyRender ++;
    },
    defaultLayerData() {
      this.listLayersVisible = [
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'business_name',
          keyMapFilter: 'business_name',
          isShowLayer: !!this.layersVisible.find(item => item.layer_field === 'business_name')?.layer_name,
          name: this.listLayerResponse.find((item) => item.layer_field === 'business_name')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'country',
          keyMapFilter: 'country',
          isShowLayer: !!this.layersVisible.find(item => item.layer_field === 'country')?.layer_name,
          name: this.listLayerResponse.find((item) => item.layer_field === 'country')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_3',
          keyMapFilter: 'layer3',
          isShowLayer: !!this.layersVisible.find(item => item.layer_field === 'layer_3')?.layer_name,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_3')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_4',
          keyMapFilter: 'layer4',
          isShowLayer: !!this.layersVisible.find(item => item.layer_field === 'layer_4')?.layer_name,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_4')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_5',
          keyMapFilter: 'layer5',
          isShowLayer: !!this.layersVisible.find(item => item.layer_field === 'layer_5')?.layer_name,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_5')?.layer_name,
        },
        {
          valueSelected: '',
          items: this.branchData.filterPatternList,
          key: 'layer_6',
          keyMapFilter: 'layer6',
          isShowLayer: !!this.layersVisible.find(item => item.layer_field === 'layer_6')?.layer_name,
          name: this.listLayerResponse.find((item) => item.layer_field === 'layer_6')?.layer_name,
        },
      ];
    },
    addOrganizational(item) {
      if (this.selectedFirst === 'organizational') return;
      this.dataList.organizational.push({
        id: item.organizational_division,
        text: item.organizational_division_value,
      });
    },
    addCompany(item) {
      if (this.selectedFirst === 'company') return;
      this.dataList.companies.push({
        text: item.company_name,
      });
    },
    addBusiness(item) {
      if (this.selectedFirst === 'business_name') return;
      this.listLayersVisible[0].items.push(item);
    },
    addCountry(item) {
      if (this.selectedFirst === 'country') return;
      this.listLayersVisible[1].items.push(item);
    },
    addLayer3(item) {
      if (this.selectedFirst === 'layer_3') return;
      this.listLayersVisible[2].items.push(item);
    },
    addLayer4(item) {
      if (this.selectedFirst === 'layer_4') return;
      this.listLayersVisible[3].items.push(item);
    },
    addLayer5(item) {
      if (this.selectedFirst === 'layer_5') return;
      this.listLayersVisible[4].items.push(item);
    },
    addLayer6(item) {
      if (this.selectedFirst === 'layer_6') return;
      this.listLayersVisible[5].items.push(item);
    },
    updateData(type) {
      this.addFilterIndex(type);
      this.updateFilter(type);

      let getLayer = {};

      this.listLayersVisible.forEach((item) => {
        if (item.valueSelected) {
          getLayer[item.key] = item.valueSelected[item.key];
        }
      });
      const dataSelect = {
        company_name: this.formData.company.text,
        organizational_division: this.formData.organizational,
        ...getLayer,
      };

      const branch_id = getBranchId(this.branchData.filterPatternList, dataSelect)?.id || null;
      if (branch_id) {
        this.$emit('updateBranchId', branch_id);
      }
    },

    addFilterIndex(type) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },

    updateFilter(type) {
      if (this.filterIndex.length === 0) {
      }
      this.filterCondition(type);
    },

    filterCondition(type) {
      if (this.filterIndex.length === 0) return;
      this.resetFilter(type);
      this.resetSelectedData(type);
      this.branchData.filterPatternList.forEach((item) => {
        this.filterLayer(type, item);
      });
    },

    resetSelectedData(type) {
      let types = [];
      let filterDataList = [];
      const filterIndexLength =
        this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataList.push(this.filterIndex[i]);
      }
      types = this.filterTypes.filter((item) => !filterDataList.includes(item));
      types.forEach((type) => {
        switch (type) {
          case 'organizational':
            this.dataList.organizational = [];
            this.formData.organizational = '';
            break;
          case 'company':
            this.dataList.companies = [];
            this.formData.company = '';
            break;
          case 'business_name':
            this.listLayersVisible[0].items = [];
            this.listLayersVisible[0].valueSelected = '';
            break;
          case 'country':
            this.listLayersVisible[1].items = [];
            this.listLayersVisible[1].valueSelected = '';
            break;
          case 'layer_3':
            this.listLayersVisible[2].items = [];
            this.listLayersVisible[2].valueSelected = '';
            break;
          case 'layer_4':
            this.listLayersVisible[3].items = [];
            this.listLayersVisible[3].valueSelected = '';
            break;
          case 'layer_5':
            this.listLayersVisible[4].items = [];
            this.listLayersVisible[4].valueSelected = '';
            break;
          case 'layer_6':
            this.listLayersVisible[5].items = [];
            this.listLayersVisible[5].valueSelected = '';
            break;
        }
      });
    },
    resetFilter(type) {
      const index = this.filterIndex.indexOf(type);
      if (index > -1) {
        this.filterIndex.splice(index + 1);
      }
    },

    filterLayer(type, item) {
      let filterDataConditionList = [];
      const filterIndexLength =
        this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataConditionList.push(this.filterIndex[i]);
      }
      for (let i = 0; i <= filterDataConditionList.length - 1; i++) {
        if (!this.filterDataByIndex(this.filterIndex[i], item)) {
          return;
        }
      }
      switch (this.filterIndex[this.filterIndex.indexOf(type)]) {
        case 'organizational':
          if (!this.filterOrganizational(item)) return;
          break;
        case 'company':
          if (!this.filterCompanies(item)) return;
          break;
        case 'business_name':
          if (!this.filterBusiness(item)) return;
          break;
        case 'country':
          if (!this.filterCountries(item)) return;
          break;
        case 'layer_3':
          if (!this.filterListLayer3(item)) return;
          break;
        case 'layer_4':
          if (!this.filterListLayer4(item)) return;
          break;
        case 'layer_5':
          if (!this.filterListLayer5(item)) return;
          break;
        case 'layer_6':
          if (!this.filterListLayer6(item)) return;
          break;
      }
      this.addFilterDataLoop(type, item);
    },
    addFilterDataLoop(type, item) {
      let types = [];
      let filterDataList = [];
      const filterIndexLength =
        this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataList.push(this.filterIndex[i]);
      }
      types = this.filterTypes.filter((item) => !filterDataList.includes(item)); // get elements not in filterTypes
      types.forEach((type) => {
        switch (type) {
          case 'organizational':
            this.addOrganizational(item);
            break;
          case 'company':
            this.addCompany(item);
            break;
          case 'business_name':
            this.addBusiness(item);
            break;
          case 'country':
            this.addCountry(item);
            break;
          case 'layer_3':
            this.addLayer3(item);
            break;
          case 'layer_4':
            this.addLayer4(item);
            break;
          case 'layer_5':
            this.addLayer5(item);
            break;
          case 'layer_6':
            this.addLayer6(item);
            break;
        }
      });
    },

    filterDataByIndex(type, item) {
      switch (type) {
        case 'organizational':
          return this.filterOrganizational(item);
        case 'company':
          return this.filterCompanies(item);
        case 'business_name':
          return this.filterBusiness(item);
        case 'country':
          return this.filterCountries(item);
        case 'layer3':
          return this.filterListLayer3(item);
        case 'layer4':
          return this.filterListLayer4(item);
        case 'layer5':
          return this.filterListLayer5(item);
        case 'layer6':
          return this.filterListLayer6(item);
        default:
          return true;
      }
    },

    filterOrganizational(item) {
      return (
        (this.formData.organizational !== '' && this.formData.organizational === item.organizational_division) ||
        this.formData.organizational === ''
      );
    },

    filterCompanies(item) {
      return (
        (this.formData.company.text !== '' && this.formData.company.text === item.company_name) ||
        this.formData.company.text === ''
      );
    },

    filterBusiness(item) {
      return (
        (this.listLayersVisible[0].valueSelected.business_name !== '' &&
          this.listLayersVisible[0].valueSelected.business_name === item.business_name) ||
        this.listLayersVisible[0].valueSelected.business_name === '' ||
        (this.listLayersVisible[0].valueSelected.business_name === TEXT_NULL && !item.business_name)
      );
    },

    filterCountries(item) {
      return (
        (this.listLayersVisible[1].valueSelected.country !== '' &&
          this.listLayersVisible[1].valueSelected.country === item.country) ||
        this.listLayersVisible[1].valueSelected.country === '' ||
        (this.listLayersVisible[1].valueSelected.country === TEXT_NULL && !item.country)
      );
    },

    filterListLayer3(item) {
      return (
        (this.listLayersVisible[2].valueSelected.layer_3 !== '' &&
          this.listLayersVisible[2].valueSelected.layer_3 === item.layer_3) ||
        this.listLayersVisible[2].valueSelected.layer_3 === '' ||
        (this.listLayersVisible[2].valueSelected.layer_3 === TEXT_NULL && !item.layer_3)
      );
    },
    filterListLayer4(item) {
      return (
        (this.listLayersVisible[3].valueSelected.layer_4 !== '' &&
          this.listLayersVisible[3].valueSelected.layer_4 === item.layer_4) ||
        this.listLayersVisible[3].valueSelected.layer_4 === '' ||
        (this.listLayersVisible[3].valueSelected.layer_4 === TEXT_NULL && !item.layer_4)
      );
    },
    filterListLayer5(item) {
      return (
        (this.listLayersVisible[4].valueSelected.layer_5 !== '' &&
          this.listLayersVisible[4].valueSelected.layer_5 === item.layer_5) ||
        this.listLayersVisible[4].valueSelected.layer_5 === '' ||
        (this.listLayersVisible[4].valueSelected.layer_5 === TEXT_NULL && !item.layer_5)
      );
    },
    filterListLayer6(item) {
      return (
        (this.listLayersVisible[5].valueSelected.layer_6 !== '' &&
          this.listLayersVisible[5].valueSelected.layer_6 === item.layer_6) ||
        this.listLayersVisible[5].valueSelected.layer_6 === '' ||
        (this.listLayersVisible[5].valueSelected.layer_6 === TEXT_NULL && !item.layer_6)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/products/linkToPrimaryData/styles/index.scss';
</style>
